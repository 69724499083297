import {
  ataccDomainCredencial as baseUrl,
} from '@/utils/paths'

import axios from 'axios'

export function credencialResource() {
  function getByUsuario() {
    return new Promise((resolve, reject) => {
      axios.get(`${baseUrl}credencial/usuario/`)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getByUsuario,
  }
}
