<template>
  <b-overlay
    id="olyLogin"
    :show="cargando"
    :rounded="overlayConfig.rounded"
    :variant="overlayConfig.variant"
    :opacity="overlayConfig.opacity"
    :blur="overlayConfig.blur"
  >
    <b-card v-if="!error">
      <b-card-text class="text-center">
        <h3 class="m-0">
          <b-button
            variant="primary"
            :disabled="disableDownload"
            @click="downloadCredential"
          >
            Descargar Credencial
          </b-button>
        </h3>
      </b-card-text>
    </b-card>
    <b-card>
      <b-card-text class="text-center">
        <div v-if="error">
          <h3>
            <font-awesome-icon
              class="text-danger"
              icon="fa-solid fa-triangle-exclamation"
            /> Hubo un error al cargar la credencial
          </h3>
          <br>
          <div>
            <b-row
              v-for="erroritem in erroresValidaciones"
              :key="erroritem.mensajeError"
              align-v="center"
            >
              <b-col>
                <strong>{{ erroritem }}</strong>
              </b-col>
            </b-row>
          </div>
          <br>
          <small>Por favor, contáctese con su delegación.</small>
          <hr>
          <b-button
            class="rounded-0"
            variant="primary"
            @click="getCredential"
          >
            Reintentar
          </b-button>
        </div>
        <img
          class="credencial-image"
          :src="credencialAsImage"
        >
      </b-card-text>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  credencialResource,
} from '@/services/credencialResource'
import {
  saveAs,
} from 'file-saver'

export default {
  name: 'Credencial',
  data() {
    return {
      overlayConfig: {
        rounded: 'lg',
        variant: 'transparent',
        opacity: '0.9',
        blur: '1rem',
        message: 'Buscando Credencial ...',
      },
      credencial: {
        bytes: [],
      },
      cargando: false,
      erroresValidaciones: [],
      error: false,
    }
  },
  computed: {
    disableDownload() {
      return this.cargando
        || this.error
    },
    credencialAsImage() {
      if (this.credencial.bytes && this.credencial.bytes.length) {
        return `data:image/png;base64,${this.credencial.bytes}`
      }
      return null
    },
  },
  created() {
    localStorage.setItem('lastPath', this.$route.path)
    this.getCredential()
  },
  methods: {
    getCredential() {
      this.cargando = true
      this.error = false
      credencialResource().getByUsuario()
        .then(data => {
          this.credencial = data
        })
        .catch(error => {
          this.erroresValidaciones = []
          this.error = true
          console.error('Hubo un error al intentar generar la credencial', error)
          if (error.data.listaMensaje
            && error.data.listaMensaje.listaErrores
            && error.data.listaMensaje.listaErrores.length > 0) {
            error.data.listaMensaje.listaErrores.forEach(e => {
              this.erroresValidaciones.push(e.mensajeError)
            })
          }
        })
        .finally(() => {
          this.cargando = false
        })
    },
    // https://stackoverflow.com/questions/35038884/download-file-from-bytes-in-javascript
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64)
      const binaryLen = binaryString.length
      const bytes = new Uint8Array(binaryLen)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      return bytes
    },
    downloadCredential() {
      const blob = new Blob([this.base64ToArrayBuffer(this.credencial.bytes)])
      saveAs(blob, 'mi-credencial-atacc.png')
    },
  },

}
</script>

<style lang="scss" scoped>
.credencial-image {
  max-width: 100%;
  width: 500px;
}
</style>
